import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { RequestStatus } from '../types/RequestStatus';
import { Feature } from 'geojson';
import axios from 'axios';
import axiosRetry from 'axios-retry';

type Atr = { id: string; data: Feature };
type AtrDataParam = {
  verification: string;
  dismissed: boolean;
  deleted: boolean;
  verified: boolean;
  unverified: boolean;
};
const atrAdapter = createEntityAdapter<Atr>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: atr => atr.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

const clearState = state => {
  state.entities = {};
  state.ids = [];
  state.selectedId = null;
  state.geojson = null;
  state.verified = [];
  state.unverified = [];
  state.dismissed = [];
  state.deleted = [];
  state.updated = new Date();
  state.status = RequestStatus.NOT_LOADED;
  state.verificationFilter = 'ALL';
};

export const atrSlice = createSlice({
  name: 'atr',
  initialState: atrAdapter.getInitialState({
    status: RequestStatus.NOT_LOADED,
    geojson: null as any,
    selectedId: null,
    showAll: true,
    verified: [] as string[],
    unverified: [] as string[],
    dismissed: [] as string[],
    deleted: [] as string[],
    verificationFilter: 'ALL',
    updated: new Date(),
  }),
  reducers: {
    atrDetectsAdded: atrAdapter.addOne,
    addAtrDetects: (state, action) => {
      // Or, call them as "mutating" helpers in a case reducer
      atrAdapter.addMany(state, action.payload);
      // state.geojson = buildGeoJson(action.payload);
      // state.historicRetrievalInfo = buildHistoricRetrievalData(action.payload);
      state.status = RequestStatus.SUCCESS;
      state.updated = new Date();
    },
    setAtrDetects: (state, action: PayloadAction<Atr[]>) => {
      atrAdapter.setAll(state, action.payload);
      // state.geojson = buildGeoJson(action.payload);
      state.selectedId = null;
      // state.historicRetrievalInfo = buildHistoricRetrievalData(action.payload);
      state.updated = new Date();
      state.status = RequestStatus.SUCCESS;
    },
    updateAtrDetects: (state, action: PayloadAction) => {
      //Not yet implemented
      //we need to be able to remove a specific datum and replace it with another
      // return state;
    },
    removeAtrDetects: state => {
      //Not yet implemented
      // return state;
    },
    clearAtrDetects: state => {
      clearState(state);
    },
    showAllDetects: state => {
      state.showAll = true;
    },
    hideAllDetects: state => {
      state.showAll = false;
    },
    setState: (state, action: PayloadAction<RequestStatus>) => {
      if (action.payload === null) return state;
      state.updated = new Date();
      state.status = action.payload;
    },
    setVerificationFilter: (state, action) => {
      state.verificationFilter = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllAtrDetects.pending, (state, { payload }) => {
      state.status = RequestStatus.PENDING;
    });
    builder.addCase(getAllAtrDetects.fulfilled, (state, { payload }) => {
      // if (payload) state.data.features = payload;
      if (payload) {
        atrAdapter.setAll(state, payload.entities);
        // const data = Object.values(payload);
        // const d2 = data.map((d: any) => d.data);

        // const gjfc: any = {
        //   type: 'FeatureCollection',
        //   features: d2,
        // };
        state.geojson = payload.response;
        state.verified = payload.verified;
        state.unverified = payload.unverified;
        state.dismissed = payload.dismissed;
        // state.historicRetrievalInfo = buildHistoricRetrievalData(payload);
        state.updated = new Date();
        state.status = RequestStatus.SUCCESS;
        // const entities = payload.flatMap(entry => {
        //   return entry.payload.map(x => {
        //     return { id: x.data.properties.id, opsBoxId: entry.opsBoxId, data: x.data};
        //   });
        // });
        // intelDataAdapter.setAll(state, payload);
        // state.selectedId = null;
      }
    });
    builder.addCase(getAllAtrDetects.rejected, (state, action) => {
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(getAtrDetectById.pending, (state, { payload }) => {
      state.status = RequestStatus.PENDING;
    });
    builder.addCase(getAtrDetectById.fulfilled, (state, { payload }) => {
      if (payload) {
        // TODO
      }
    });
    builder.addCase(getAtrDetectById.rejected, (state, action) => {
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(clearAtrDetects, (state, action) => {
      clearState(state);
    });
    builder.addCase(setAtrDetects, (state, action) => {
      clearState(state);
    });
  },
});

const getData = async (requestUrl: string) => {
  const client = axios.create();
  axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
  });
  const response = await client.get(requestUrl);
  return response.data;
};

/**
 * Thunk to retrieve all atr detects
 */
export const getAllAtrDetects = createAsyncThunk('atr/getAllAtrDetects', async (arg: any, thunkAPI) => {
  try {
    const response = await getData(arg);

    // const mr =response.flatMap(entry => {
    //   return entry.payload.map(p => p.data);
    // });
    // const entities = response.features.flatMap(entry => {
    //   return entry.map(x => {
    //     return { id: x.id, data: x.data };
    //   });
    // }
    const verified: string[] = [];
    const unverified: string[] = [];
    const dismissed: string[] = [];
    const entities = response.features.map(entry => {
      const verifiedState = entry.properties['Verification'];
      if (verifiedState && verifiedState.toLowerCase() === 'verified') {
        verified.push(entry.id);
      }
      if (verifiedState && verifiedState.toLowerCase() === 'dismissed') {
        dismissed.push(entry.id);
      }
      if (verifiedState && verifiedState.toLowerCase() === 'unverified') {
        unverified.push(entry.id);
      }
      return { id: entry.id, data: entry };
    });
    console.log(`getAllAtr ${entities}`);
    return { response, entities, verified, unverified, dismissed };
  } catch (ex) {
    //@ts-ignore
    console.error(`Atr Error occurred calling '${arg}', code=${ex.code}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const getAtrDetectById = createAsyncThunk('atr/getAtrDetectById', async (arg: any, thunkAPI) => {
  return thunkAPI.rejectWithValue('Not implemented');
});

export const {
  selectById: selectAtrDetectById,
  selectIds: selectAtrDetectIds,
  selectEntities: selectAtrDetectEntities,
  selectAll: selectAllAtrDetects,
  selectTotal: selectTotalAtrDetects,
} = atrAdapter.getSelectors((state: any) => state.atr);

const buildGeoJson = payload => {
  const data = Object.values(payload);
  const d2 = data.map((d: any) => d.data);

  const gjfc: any = {
    type: 'FeatureCollection',
    features: d2,
  };
  return gjfc;
};

const showAllState = state => state.atr.showAll;
export const selectAtrShowAll = createSelector([showAllState], x => x);

export const dismissedState = state => state.atr.dismissed;
export const verifiedState = state => state.atr.verified;
export const unverifiedState = state => state.atr.unverified;
const atrGeojsonState = state => state.atr.geojson;
export const selectAtrGeojson = createSelector([atrGeojsonState], x => x);

export const selectAtrAsGeoJSON = (args: AtrDataParam) =>
  createSelector(
    [state => selectAllAtrDetects(state), dismissedState, verifiedState, unverifiedState],
    (detects, dismissed: any, verified: any, unverified: any) => {
      const filteredDetects = detects.filter(data => {
        if (args.verification && args.verification.toLowerCase() === 'all') {
          return true;
        }
        let filter = false;
        if (!args.dismissed) {
          filter = dismissed.includes(data.id);
        }
        if (!args.verified) {
          filter = filter || verified.includes(data.id);
        }
        if (!args.unverified) {
          filter = filter || unverified.includes(data.id);
        }
        return !filter;
      });
      return buildGeoJson(filteredDetects);
    },
  );

export const {
  addAtrDetects,
  setAtrDetects,
  updateAtrDetects,
  removeAtrDetects,
  clearAtrDetects,
  showAllDetects,
  hideAllDetects,
  setState,
} = atrSlice.actions;
export default atrSlice.reducer;
